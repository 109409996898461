:root {
  --appHeaderHeight: 6rem;
  --appContentTopPadding: 1rem;
  --appContentHeight: calc(
    100vh - var(--appHeaderHeight) - var(--appContentTopPadding)
  );
}

.appHeader {
  display: flex;
  justify-content: space-between;
  background-color: #0277bd;
  color: white;
  padding: 1em;
  height: var(--appHeaderHeight);
}

.appHeader h4 {
  overflow: hidden;
}

.appHeaderActions {
  list-style: none;
  white-space: nowrap;
}

.appHeaderActions > li {
  margin-top: 1rem;
}

.appHeaderActions a {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  padding: 1.5rem 1rem;
}

.appHeaderActions a:hover {
  color: white;
}

.appContent {
  height: calc(100vh - var(--appHeaderHeight));
  overflow-y: auto;
  padding-top: 1rem;
}
